<template>
    <div>
        <!-- 头部框 -->
            <div class="box-card-tip">
                <div>
                    <i class="iconfont iconicon-13" ></i>
                    <span>您本次共上传发票<strong> {{ uploadNum }} </strong>张，取消上传<strong> {{ delNum }} </strong>张，剩余可上传<strong> {{ ConfirmNum }} </strong>张</span>
                </div>
                <el-button type="primary" class="download" @click="batchDownLoad" >确认上传</el-button>
            </div>
        <!-- 内容区域 -->
        <div class="contain">
            <!-- 确认区域 -->
            <div class="compNav">
                <div class="compNavIcon"></div>
                <p class="font_18">本次确认上传的文件</p>
            </div>
            <div class="itemConfirm" v-for="item in itemConfirm" :key="item.id"> 
                <!-- 预览item -->
                <p class="privewItem" @click="privewHandle">{{item.originalFileName}}</p>
                <i class="el-icon-delete" @click="delHandle(item.id)"></i>
            </div>

            <!-- 取消区域 -->
            <div class="compNav">
                <div class="compNavIcon"></div>
                <p class="font_18">本次取消上传的文件</p>
            </div>
            <div class="itemCancel" v-for="(item) in itemCancel" :key="item.id" @click="addHandle(item.id)"> 
                <p><s>{{item.originalFileName}}</s></p>
                <!-- <i class="el-icon-delete"></i> -->
            </div>
        </div>
        <!-- 引入组件 -->
        <!-- <preview-dialog :visible='visible' :cancelDialog='pdfCancel'></preview-dialog> -->
    </div>
</template>

<script>
// import previewDialog from '../../../components/previewDialog'
export default {
    name: 'WebIndex',
    components:{
        // previewDialog
    },

    data() {
        return {
            queryForm: {
                invoiceAmount:null,
            },
        tableData: [{
            date: '2016-05-03',
            name: '王小虎',
            province: '上海',
            city: '普陀区',
            address: '上海市普陀区金沙江路 1518 弄',
            zip: 200333
        }],
        fileListConfirm:[{
            name:'042001900211_1000.pdf',
            id:1
        },
        {
            name:'042001900211_2000.pdf',
            id:2
        },
        {
            name:'042001900211_3000.pdf',
            id:3
        },
        {
            name:'042001900211_4000.pdf',
            id:4
        },
        {
            name:'042001900211_5000.pdf',
            id:5
        },
        {
            name:'042001900211_6000.pdf',
            id:6
        }],
        fileListCancel:[{
            name:'042001900211_7000.pdf',
            id:7
        },
        {
            name:'042001900211_8000.pdf',
            id:8
        },
        {
            name:'042001900211_9000.pdf',
            id:9
        },
        {
            name:'042001900211_11000.pdf',
            id:10
        },
        {
            name:'042001900211_12000.pdf',
            id:11
        },
        {
            name:'042001900211_14000.pdf',
            id:12
        }],
        visible: false,
        };
    },

    mounted(id) {
        //监听父级嵌套页 传递消息
        window.addEventListener('message', this.updateMessage);
    },

    methods: {
        // 使用
        updateMessage(e){ //监听父页面的方法 进行回调
          console.log(e);
          if(e.data != undefined && e.data != ''){
            console.log(e,'---',e.data);
          }
        },
        batchDownLoad(){
            //当前为iframe页面 发送消息
            window.parent.postMessage("我是子页面的test！", '*');
        },
        privewHandle(){
            this.visible = true
        },
        delHandle(id){
        let delItem = this.fileListConfirm.filter((item)=>item.id==id)
        this.fileListCancel.unshift(...delItem)
        this.fileListConfirm= this.fileListConfirm.filter((item)=>item.id!=id)
        // console.log(delItem,'1111')
        },
        addHandle(id){
        let addItem = this.fileListCancel.filter((item)=>item.id==id)
        this.fileListConfirm.unshift(...addItem)
        this.fileListCancel= this.fileListCancel.filter((item)=>item.id!=id)
        // console.log(delItem,'222')
        },  
        //会话框取消按钮
        pdfCancel(){
            this.visible = false
        }
    },
    computed:{
        itemConfirm(){
            return this.fileListConfirm
        },
        itemCancel(){
            return this.fileListCancel
        },
        uploadNum(){
            return this.fileListConfirm.length + this.fileListCancel.length
        },
        delNum(){
            return this.fileListCancel.length
        },
        ConfirmNum(){
            return this.fileListConfirm.length
        }
        
    }
};
</script>

<style lang="less" scoped>
.box-card-tip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1564px;
    height: 60px;
    padding: 22px;
    margin-bottom: 22px;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    background-color: #fff;
    .iconicon-13 {
        color: rgba(255, 151, 0);
        padding-right: 6px;
    }
    .download {
    width: 100px;
    height: 40px;
    padding: 0;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    // color: #ffffff;
    }
    strong {
        color: #409eff;
        font-size:18px ;
    }
}
.contain{
    width: 1564px;
    height: 1370px;
    background-color: #fff;
    padding:20px; 
    .compNav {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid #d1d1d1;
        margin-bottom: 24px;
        font-size: 16px;
        .compNavIcon {
            width: 4px;
            height: 16px;
            margin-right: 12px;
            background-color: #409eff;

            border-radius: 50em;
        }
    }
    .font_18 {
        font-size: 18px;
    }

}
.itemConfirm, .itemCancel{
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 380px;
    height: 45px;
    padding: 10px;
    font-size: 16px;
    line-height: 30px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    p {
        // width: 350px;
        margin: 0;
        s {
            color: #666;
        }
    }
    .el-icon-delete {
        padding-right:100px;
        opacity: 0;
        cursor:pointer;
    }
}

.itemConfirm:hover {
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
    i {
        opacity: 1;
    }
}
.privewItem {

}
.itemCancel {
    cursor:pointer;
}

.el-col-12 {
    margin: 0;
}

/deep/.form-inline {
    .double_query {
        .el-form-item__content {
        width: 70% !important;
        margin-right:0 !important;
        }
    }
    .single_query {
        .el-form-item__content {
        width: 84% !important;
        margin-right:0 !important;
        }
    }
}
.compNavT {
    padding: 24px 0;
    font-size: 16px;
}
.invoice-Template {
    position:sticky;
    top: 0;
}




</style>